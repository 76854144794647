
import {
  defineComponent,
  onMounted,
  ref,
  useContext,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'MainAbout',
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const aboutSection = ref();
    const leftBlock = ref();
    const rightBlock = ref();
    const { $gsap } = useContext();

    const animateOnScroll = () => {
      const instance = $gsap.matchMedia();
      instance.add('(min-width: 1024px)', () => {
        $gsap.fromTo(
          leftBlock.value,
          {
            y: 0,
          },
          {
            y: -50,
            ease: 'power3.out',
            scrollTrigger: {
              scrub: 1,
              trigger: aboutSection.value.$el,
              start: 'top center',
              end: 'bottom 100px',
              toggleActions: 'restart none none reverse',
            },
          }
        );

        $gsap.fromTo(
          rightBlock.value,
          {
            y: 0,
          },
          {
            y: 50,
            ease: 'power3.out',
            scrollTrigger: {
              scrub: 1,
              trigger: aboutSection.value.$el,
              start: 'top center',
              end: 'bottom 100px',
              toggleActions: 'restart none none reverse',
            },
          }
        );
      });
      instance.add('(max-width: 1023px)', () => {
        $gsap.fromTo(
          leftBlock.value,
          {
            y: 70,
          },
          {
            y: 0,
            ease: 'power3.out',
            scrollTrigger: {
              scrub: 0.5,
              trigger: aboutSection.value.$el,
              start: 'top center',
              end: 'bottom 100px',
              toggleActions: 'restart none none reverse',
            },
          }
        );

        $gsap.fromTo(
          rightBlock.value,
          {
            y: 90,
          },
          {
            y: -20,
            ease: 'power3.out',
            scrollTrigger: {
              scrub: 1,
              trigger: aboutSection.value.$el,
              start: 'top center',
              end: 'bottom 100px',
              toggleActions: 'restart none none reverse',
            },
          }
        );
      });
    };

    onMounted(() => {
      animateOnScroll();
    });

    return {
      aboutSection,
      leftBlock,
      rightBlock,
    };
  },
});
