
import { computed, defineComponent } from '@nuxtjs/composition-api';

const paddingTop = {
  sm: 'pt-10 md:pt-10',
  md: 'pt-10 md:pt-14',
  lg: 'pt-[70px] md:pt-20 xl:pt-24 2xl:pt-32',
};

const paddingBottom = {
  sm: 'pb-10 md:pb-10',
  md: 'pb-10 md:pb-14',
  lg: 'pb-[70px] md:pb-20 xl:pb-24 2xl:pb-32',
};

export default defineComponent({
  name: 'AppSection',
  props: {
    paddingTop: {
      type: String,
      default: 'lg',
    },
    paddingBottom: {
      type: String,
      default: 'lg',
    },
  },

  setup(props) {
    const paddingTopClasses = computed(() => paddingTop[props.paddingTop]);
    const paddingBottomClasses = computed(
      () => paddingBottom[props.paddingBottom]
    );

    return {
      paddingTopClasses,
      paddingBottomClasses,
    };
  },
});
